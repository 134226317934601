<template>
  <v-container class="not-found">
    <v-row no-gutters style="height: 100vh;">
      <v-col :align-self="'center'" justify-center class="text-center">
        <div class="text-vertical-center text-center">
          <img width="300px" style="margin: 0 auto;" src="logo.svg" />
          <h1>Pagina non trovata!</h1>
          <h3>Ci dispiace. La pagina che hai cercato non è stata trovata.</h3>
          <h4>Ti invitiamo a effettuare una nuova ricerca.</h4>
          <router-link
            color="primary"
            style="text-decoration: none"
            :to="{ name: 'Home' }"
          >
            TORNA ALLA HOMEPAGE
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFound"
};
</script>
<style lang="scss" scoped>
html,
body {
  width: 100% !important;
  height: 100% !important;
}
body {
  margin: 0 !important;
}
</style>
